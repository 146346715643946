.OTSubscriberContainer {
  width: 100% !important;
  height: 100vh !important;
}

.OTPublisherContainer {
  width: 15rem !important;
  height: 15rem !important;
}

.fc-bg-event {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #68c7c5 !important;
  border: 0.5px dashed black;
}

.fc-event {
  overflow: hidden;
}

a.fc-event:hover {
  cursor: pointer;
}

.MuiAppBar-root {
  background-color: #fafafa !important;
}

.MuiDialog-paperWidthSm {
  max-width: inherit !important;
}

.MuiListItem-button.Mui-selected {
  background-color: rgba(0, 59, 92, 0.1) !important;
  border-color: rgba(0, 59, 92, 1);
  color: rgba(0, 59, 92, 1);
}

.OT_video-poster { 
  z-index: 0;
}